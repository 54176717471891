import { createApi } from '@reduxjs/toolkit/query/react'
import { getConsoleBaseQuery } from './baseQueryConsole'

import {
  TRHello, TRUser, TRPutUserNickname, TRPutUserProfile, TRDeleteUserToken,
  TRPutUserPassword, TRPutUserSettings, TRUserItem, TRPostAddUser, TRPutUserSyncDesc,
} from '../../models'
import { TRSyncPage, TRSyncParentPage, TRSyncProductPayload } from '../../models/sync'


export const api = createApi({
  baseQuery: getConsoleBaseQuery(),
  refetchOnReconnect: true,
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    sayHello: builder.query<TRHello, void>({
      query: () => ({
        url: '/hello',
        method: 'GET',
      }),
    }),
    getUsers: builder.query<TRUserItem[], void>({
      query:  () => ({
        url: '/users',
        method: 'GET',
      }),
    }),
    register: builder.mutation<TRUserItem, TRPostAddUser>({
      query: (payload) => ({
        url: '/users',
        method: 'POST',
        body: payload,
      }),
    }),
    getUser: builder.query<TRUser, string|undefined>({
      query: (id) => ({
        url: `/users/${id}`,
        method: 'GET',
      }),
      // TODO Зачем?
      // forceRefetch({ currentArg, previousArg }) {
      //   return currentArg !== previousArg
      // },
    }),
    updateProfile: builder.mutation<void, TRPutUserProfile>({
      query: (payload) => ({
        url: `/users/${payload.id}?form=profile`,
        method: 'PUT',
        body: payload,
      }),
    }),
    updateSyncDest: builder.mutation<void, TRPutUserSyncDesc>({
      query: (payload) => ({
        url: `/users/${payload.id}?form=syncDest`,
        method: 'PUT',
        body: {
          syncDest: payload.syncDest,
        },
      }),
    }),
    getSyncDestinationPages: builder.query<
      TRSyncParentPage | TRSyncPage,
      {prodId: string, qs: string, syncDestId: number}
    >({
      query: ({ prodId, qs , syncDestId }) => {
        return {
          url: `/products/${prodId}/sync/${syncDestId}?${qs}`,
          method: 'GET',
        }
      },
    }),
    getProductSyncPayload: builder.query<TRSyncProductPayload[], {prodId: string}>({
      query: ({ prodId }) => ({
        url: `/products/${prodId}/sync/payload`,
        method: 'GET',
      }),
    }),
    createSyncDestinationPage: builder.query<
      {id: number},
      {
        prodId: string,
        parentPageId: number,
        slug: string,
        title: string,
        payload: TRSyncProductPayload[],
        syncDestId: number
      }
    >({
      query: ({ prodId, parentPageId, slug, title, payload, syncDestId }) => ({
        url: `/products/${prodId}/sync/${syncDestId}`,
        method: 'POST',
        body: { prodId, parentPageId, slug, title, payload },
      }),
    }),
    deleteSyncDestinationPage: builder.mutation<void, {prodId: string, syncDestId: number, externalPageID: number}>({
      query: ({ prodId, syncDestId, externalPageID }) => ({
        url: `/products/${prodId}/sync/${syncDestId}/${externalPageID}`,
        method: 'DELETE',
      }),
    }),
    deleteToken: builder.mutation<void, TRDeleteUserToken>({
      query: (payload) => ({
        url: `/users/${payload.id}/tokens/${payload.deviceId}`,
        method: 'DELETE',
      }),
    }),
    updateNickname: builder.mutation<void, TRPutUserNickname>({
      query: (payload) => ({
        url: `/users/${payload.id}?form=nickname`,
        method: 'PUT',
        body: payload,
      }),
    }),
    updatePassword: builder.mutation<void, TRPutUserPassword> ({
      query: (payload) => ({
        url: `/users/${payload.id}?form=password`,
        method: 'PUT',
        body: payload,
      }),
    }),
    updateSettings: builder.mutation<void, TRPutUserSettings> ({
      query: (payload) => ({
        url: `/users/${payload.id}?form=settings`,
        method: 'PUT',
        body: payload,
      }),
    }),
  }),
})

export const {
  useLazyGetUserQuery,
  useLazySayHelloQuery,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useRegisterMutation,
  useGetUserQuery,
  useUpdateProfileMutation,
  useUpdateSyncDestMutation,
  useUpdateNicknameMutation,
  useDeleteTokenMutation,
  useUpdatePasswordMutation,
  useUpdateSettingsMutation,
  useLazyGetSyncDestinationPagesQuery,
  useLazyGetProductSyncPayloadQuery,
  useLazyCreateSyncDestinationPageQuery,
  useDeleteSyncDestinationPageMutation,
} = api
