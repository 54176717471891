import React from 'react'
import { styled } from '@mui/material/styles'

import { Container } from '@mui/material'
import { Outlet } from 'react-router-dom'
import BaseAppBar from './BaseAppBar'
import { drawerWidth } from './BaseAppBar/constants/app-bar-constants'
import Notifications from './Notification/Notifications'


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{ open?: boolean; }>
(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}))

const Layout = (): JSX.Element => (
  <Main open={true}>
    <BaseAppBar />
    <Notifications />

    <Container sx={{ mt: '48px' }}>
      <Outlet />
    </Container>
  </Main>
)


export default Layout
