// console application run at domains like console.example.com
let baseRestUrl = ''

if (process.env.NODE_ENV === 'development') {
  if (!process.env.REACT_APP_BASE_URL) {
    throw new Error('env REACT_APP_BASE_URL is undefined')
  }

  baseRestUrl = process.env.REACT_APP_BASE_URL
} else {
  baseRestUrl = `${window.location.protocol}//${window.location.host}`
}


type TBaseUrl = {
  readonly website: string
  readonly websiteApi: string
}

export const baseUrl: TBaseUrl = {
  website: baseRestUrl,
  websiteApi: '/api',
}
