import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AlertColor } from '@mui/material'


export type TNotification = {
  message: string
  type: AlertColor
}

type TNotificationPayload = {
  message: string,
  type: AlertColor
}

type TState = {
  item?: TNotification
}


const initialState: TState = {
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification(state, action: PayloadAction<TNotificationPayload>) {
      state.item = ({
        ...action.payload,
      })
    },
    deleteNotification(state) {
      state.item = undefined
    },
  },
})

export default notificationsSlice.reducer

export const { addNotification, deleteNotification } = notificationsSlice.actions
