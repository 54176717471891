import React, { ReactElement, useState } from 'react'
import { useSelector } from 'react-redux'

import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import DrawerComponent from './Drawer'
import ProfileMenu from './ProfileMenu'
import { drawerWidth } from './constants/app-bar-constants'
import { selectSidebarOpen, sidebarSlice } from '../../../redux/reducers/sidebar'
import { useAppDispatch } from '../../../hooks/redux'


type AppBarProps = {
  open?: boolean;
} & MuiAppBarProps

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

export const BaseAppBar = (): ReactElement => {
  const sidebarOpen = useSelector(selectSidebarOpen)
  const dispatch = useAppDispatch()

  const { toggleSidebar } = sidebarSlice.actions

  const handleSidebarBtnOpenClick = (): void => {
    dispatch(toggleSidebar())
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" open={sidebarOpen}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 0 }}
            onClick={handleSidebarBtnOpenClick}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ flexGrow: 1 }} />
          <ProfileMenu/>
        </Toolbar>
      </AppBar>

      <DrawerComponent />
    </Box>
  )
}
