import React, { ReactElement, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import { Avatar, Box, ListItemIcon, ListItemText } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import SettingsIcon from '@mui/icons-material/Settings'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { useLazyLogoutQuery } from '../../../redux/api/apiWebsite'
import { notificationsSlice } from '../../../redux/reducers/notifications'
import { useAppSelector } from '../../../hooks/redux'
import { getUserPicPath } from '../../../utils/image'


const ProfileMenu = (): ReactElement => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const divRef = useRef<HTMLDivElement>(null)
  const me = useAppSelector(state => state.hello.me)
  const syncDest = useAppSelector(state => state.hello.syncDest)

  const [logout] = useLazyLogoutQuery()

  const handleLogout = async (): Promise<void> => {
    setOpen(false)
    try {
      await logout().unwrap()
      navigate('/login')
    } catch (e) {
      dispatch(notificationsSlice.actions.addNotification({
        type: 'error',
        message: 'Ошибка выхода из системы',
      }))
    }
  }

  const activeSyncDestHostname = syncDest.find(({ id }) => id === me.syncDest)?.hostname || 'не выбран внешний ресурс'

  return (
    <>
      <Box sx={{ display: { md: 'flex' } }} ref={divRef}>
        <IconButton
          size="large"
          edge="end"
          aria-label="меню пользователя"
          aria-controls={'profile-menu'}
          aria-haspopup="true"
          onClick={(): void => setOpen(true)}
          color="inherit"
          sx={{ borderRadius: '16px', padding: '4px 8px 4px 16px' }}
        >
          <Box lineHeight={0} textAlign={'right'} maxWidth={240} mr={1.5} height={32}>
            <Typography variant="body1" fontWeight={500} lineHeight={1.1} noWrap>{me.fullname}</Typography>

            <Typography variant="caption" lineHeight={1} noWrap>
              <span style={{ opacity: .8 }}>@{me.nickname} ({activeSyncDestHostname})</span>
            </Typography>
          </Box>

          <Avatar
            sx={{ width: '40px', height: '40px' }}
            variant='circular'
            src={getUserPicPath(me.userpic, 40)}
          />

          <ArrowDropDownIcon />
        </IconButton>
      </Box>

      <Menu
        id={'profile-menu'}
        anchorEl={divRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={(): void => setOpen(false)}
        keepMounted
      >
        <MenuItem component={Link} to={`/users/${me.id}`} sx={{ width: '200px' }} onClick={(): void => setOpen(false)}>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>

          <ListItemText>
            Настройки
          </ListItemText>
        </MenuItem>

        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>

          <ListItemText>
            Выход
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}

export default ProfileMenu
