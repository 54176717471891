import React, { Suspense, lazy, useEffect } from 'react'

import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import Layout from './components/Layout'

import { useLazySayHelloQuery } from './redux/api/api'
import { useAppAuthentication } from './hooks/useAppAuthentication'


const Users = lazy(() => import('./components/Layout/Users'))
const User = lazy(() => import('./components/Layout/User'))
const Login = lazy(() => import('./components/Login'))
const TurboCompressors = lazy(() => import('./components/Layout/TurboCompressors'))
const TurboCompressor = lazy(() => import('./components/Layout/TurboCompressor'))
const PageNotFound = lazy(() => import('./components/Layout/PageNotFound'))

type TWrappedRouteProps = {
  children: JSX.Element
}

const WrappedRoute = (props: TWrappedRouteProps): JSX.Element => {
  return (
    <Suspense fallback={<CircularProgress />}>
      {props.children}
    </Suspense>
  )
}

const Routing = (): JSX.Element | null => {
  const { isAuthenticated, isLoading: isAuthenticating } = useAppAuthentication()
  const [ sayHello, result ] = useLazySayHelloQuery()

  useEffect(() => {
    if (isAuthenticated) {
      sayHello()
    }
  }, [isAuthenticated, sayHello])

  if ((isAuthenticating || !result.data) && window.location.pathname !== '/login') {
    return <CircularProgress />
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/login"
          element={
            <WrappedRoute><Login /></WrappedRoute>
          }
        />

        <Route
          path="/"
          element={
            <WrappedRoute><Layout /></WrappedRoute>
          }
        >
          <Route
            path='users'
            element={
              <WrappedRoute><Users /></WrappedRoute>
            }
          />

          <Route
            path='users/:id'
            element={
              <WrappedRoute><User /></WrappedRoute>
            }
          />

          <Route
            path='turbocompressors'
            element={
              <WrappedRoute><TurboCompressors /></WrappedRoute>
            }
          />

          <Route
            path='turbocompressors/:id'
            element={
              <WrappedRoute><TurboCompressor /></WrappedRoute>
            }
          />

          <Route
            path="*"
            element={<PageNotFound/>}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Routing
