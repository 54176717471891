import { useDispatch } from 'react-redux'
import { useRefreshTokenMutation } from '../redux/api/apiWebsite'
import { helloSlice } from '../redux/reducers/hello'


type TResult = {
  isAuthenticated: boolean,
  isLoading: boolean
}

export const AccessTokenBroadcastChannel = new BroadcastChannel('accessToken')

export const useAppAuthentication = (): TResult => {

  const result:  TResult = {
    isAuthenticated: false,
    isLoading: true,
  }
  const [ userRefreshToken, userRefreshTokenState ] = useRefreshTokenMutation()
  const dispatch = useDispatch()

  if (window.location.pathname !== '/login') {
    (async (): Promise<void> => {
      if (userRefreshTokenState.isUninitialized) {
        await userRefreshToken()
      }
    })()

    if (userRefreshTokenState.isError) {
      window.location.href = '/login'
    }

    if (userRefreshTokenState.isSuccess) {
      AccessTokenBroadcastChannel.onmessage = (e: MessageEvent<string>): void => {
        dispatch(helloSlice.actions.setToken(e.data))
      }

      result.isAuthenticated = true
      result.isLoading = false
    }
  } else {
    result.isLoading = false
  }

  return result
}
