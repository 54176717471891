import { createApi } from '@reduxjs/toolkit/query/react'
import { FetchArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import { getWebsiteBaseQuery } from './baseQueryWebsite'


type TLoginResponse = {
  token: string
  refreshToken?: string
}

type TLoginRequest = {
  login: string
  password: string
  remember: boolean
}

export const apiWebsite = createApi({
  reducerPath: 'website',
  baseQuery: getWebsiteBaseQuery(),
  endpoints: (builder) => ({
    login: builder.mutation<TLoginResponse, TLoginRequest>({
      query: (arg) => ({
        method: 'POST',
        url: '/user/tokens',
        body: arg,
      }),
    }),
    refreshToken: builder.mutation<TLoginResponse, void>({
      query: () => {
        const baseQuery: FetchArgs =  {
          url: '/user/tokens',
          method: 'PUT',
        }

        if (process.env.NODE_ENV === 'development') {
          const refreshToken = sessionStorage.getItem('refreshToken')
          if (refreshToken) {
            baseQuery.body = { refreshToken }
          }
        }

        return baseQuery
      },
    }),
    logout: builder.query<void, void>({
      query: () => ({
        url: '/user/tokens',
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useLoginMutation,
  useRefreshTokenMutation,
  useLazyLogoutQuery,
} = apiWebsite
