import * as React from 'react'
import { useSelector } from 'react-redux'


import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import MuiAlert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'

import { notificationsSlice, TNotification } from '../../../redux/reducers/notifications'
import { useAppDispatch } from '../../../hooks/redux'
import { selectSidebarOpen } from '../../../redux/reducers/sidebar'
import { drawerWidth } from '../BaseAppBar/constants/app-bar-constants'


const HIDE_DURATION = 6000
const LEFT_OFFSET = 10

const Notification = (props: TNotification): JSX.Element => {
  const sidebarOpen = useSelector(selectSidebarOpen)
  const { deleteNotification } = notificationsSlice.actions
  const dispatch = useAppDispatch()

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ): void => {
    if (reason === 'clickaway') {
      return
    }

    dispatch(deleteNotification())
  }

  React.useEffect(() => {
    setTimeout(() => {
      dispatch(deleteNotification())
    }, HIDE_DURATION)
  }, [ dispatch, deleteNotification ])

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small"/>
      </IconButton>
    </React.Fragment>
  )

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={true}
        onClose={handleClose}
        action={action}
        style={{
          marginLeft: sidebarOpen ? drawerWidth + LEFT_OFFSET : LEFT_OFFSET,
          width: 300,
          marginTop: 20,
          position: 'static',
        }}
      >
        <MuiAlert onClose={handleClose} variant='filled' severity={props.type}>{props.message}</MuiAlert>
      </Snackbar>
    </div>
  )
}

export default Notification
