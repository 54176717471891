import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { useNavigate } from 'react-router-dom'

import { styled } from '@mui/material/styles'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'

import { Collapse, Divider, useTheme, Backdrop } from '@mui/material'
import logo from '../../../img/logo.svg'

import { actionsMap } from './constants/actionsMap'
import { drawerWidth } from './constants/app-bar-constants'
import ActionItem from './ActionItem'
import { selectSidebarOpen, sidebarSlice } from '../../../redux/reducers/sidebar'
import { useAppDispatch } from '../../../hooks/redux'


export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'center',
}))

const DrawerComponent = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { toggleSidebar } = sidebarSlice.actions

  const [ expandedCollapse, setExpandedCollapse ] = useState<string[]>([])
  const theme = useTheme()

  const isOpen = useSelector(selectSidebarOpen)

  const getActionClickCallback = (hasChild: boolean): ((key: string) => void) => {
    return hasChild ? handleCollapseClick : handleOrphanClick
  }

  const handleCollapseClick = (key: string): void => {
    const expandedCollapseCopy = [ ...expandedCollapse ]

    if (expandedCollapse.includes(key)) {
      const index = expandedCollapseCopy.indexOf(key)

      expandedCollapseCopy.splice(index, 1)
    } else {
      expandedCollapseCopy.push(key)
    }

    setExpandedCollapse(expandedCollapseCopy)
  }

  const handleOrphanClick = (key: string): void => {
    dispatch(toggleSidebar())
    navigate(key)
  }

  const handleBackdropClick = (): void => {
    dispatch(toggleSidebar())
  }

  const _renderActions = (): JSX.Element[] => (
    actionsMap.map(item => {
      const expanded = expandedCollapse.includes(item.key)
      const hasChild = item.child.length > 0

      return (
        <div key={item.key}>
          <ActionItem
            item={item}
            shouldHaveExpand={hasChild}
            selected={expanded}
            onClickCallback={getActionClickCallback(hasChild)}
          />

          {
            hasChild &&
            item.child.map((child_item, index) => (
              <Collapse
                in={expanded}
                timeout="auto"
                unmountOnExit
                key={index}
              >
                <List component='div' disablePadding>
                  <ActionItem
                    item={child_item}
                    onClickCallback={getActionClickCallback(false)}
                  />
                </List>
              </Collapse>
            ))
          }
        </div>
      )
    })
  )

  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={isOpen}
      >
        <DrawerHeader theme={theme}>
          <img src={logo} style={{ height: '20px' }} alt="Лого SMTurbo"/>
        </DrawerHeader>

        <Divider />

        <List>
          {_renderActions()}
        </List>
      </Drawer>

      <Backdrop
        sx={{ color: '#fff', zIndex: (t) => t.zIndex.drawer + 1, marginLeft: `${drawerWidth}px` }}
        open={isOpen}
        onClick={handleBackdropClick}
      />
    </>
  )
}

export default DrawerComponent
