import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TRSyncPage, TRSyncParentPage, TRSyncProductPayload } from '../../models/sync'
import { api } from '../api/api'
import { TProductListItemSyncDest, TSingleProductListItem } from '../api/apiProducts'


type TProps = {
  destinationPageId: number,
  product?: TSingleProductListItem,
  page?: TRSyncPage | TRSyncParentPage
  payload?: TRSyncProductPayload[]
}

const initialState: TProps = {
  destinationPageId: 0,
  product: undefined,
  page: undefined,
  payload: undefined,
}

export const syncBrowserSlice = createSlice({
  name: 'syncBrowser',
  initialState,
  reducers: {
    setDestinationPageId: (state, action: PayloadAction<number>) => {
      state.destinationPageId = action.payload
      state.payload = undefined
    },
    setProduct: (state, action: PayloadAction<TSingleProductListItem>) => {
      state.product = action.payload
      state.page = undefined
    },
    unsetProduct: (state) => {
      state.destinationPageId = 0
      state.product = undefined
      state.page = undefined
      state.payload = undefined
    },
    setProductSyncDest: (state, { payload }: PayloadAction<TProductListItemSyncDest>) => {
      if (state.product) {
        state.product.syncDest.push(payload)
      }
    },
    unsetProductSyncDest: (state, { payload }: PayloadAction<{syncDestId: number}>) => {
      if (state.product) {
        state.product.syncDest = state.product.syncDest.filter(d => d.id !== payload.syncDestId)
      }
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(api.endpoints?.getSyncDestinationPages.matchFulfilled, (state, { payload }) => {
        if (('page' in payload && 'children' in payload) || ('id' in payload)) {
          state.page = payload
        } else {
          state.page = undefined
        }
      })
      .addMatcher(api.endpoints?.getProductSyncPayload.matchFulfilled, (state, { payload }) => {
        state.payload = payload
      })
  },
})


export default syncBrowserSlice.reducer
