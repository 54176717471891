import { configureStore } from '@reduxjs/toolkit'

import { api } from './api/api'
import { apiWebsite } from './api/apiWebsite'
import { apiProducts } from './api/apiProducts'

import rootReducer from './reducers'



export type TAppState = ReturnType<typeof rootReducer>
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
    apiWebsite.middleware,
    api.middleware,
    apiProducts.middleware,
  ),
})

// export type TAppState = ReturnType<typeof store.getState>
export type TAppDispatch = typeof store.dispatch

export default store
