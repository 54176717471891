export type TFilter = {
    name: string,
    label: string,
    type: string,
    startIcon?: JSX.Element,
    inputType?: string,
    depsToLoad?: string[],
    clearBottomOnChange?: boolean,
    [key: string]: string | undefined | string[] | null | boolean | JSX.Element,
}

export type TSection = {
    code: string,
    name?: string,
    filters: TFilter[]
}

export const queryFilters: TSection[] = [
  {
    code: 'turbine',
    filters: [
      {
        name: 'q',
        label: 'Номер турбины',
        type: 'input',
      },
      {
        name: '_man',
        label: 'Производитель',
        type: 'select',
      },
      {
        name: '_iss',
        label: 'Год выпуска',
        type: 'input',
        inputType: 'number',
      },
    ],
  },
  {
    code: 'vehicle',
    name: 'Транспортное средство',
    filters: [
      {
        name: '_car',
        label: 'Производитель',
        type: 'select',
        depsToLoad: ['_mod'],
        clearBottomOnChange: true,
      },
      {
        name: '_mod',
        label: 'Модель',
        type: 'select',
        depsToLoad: ['_cap', '_det', '_pow'],
        clearBottomOnChange: true,
      },
      {
        name: '_cap',
        label: 'Объем двигателя',
        type: 'select',
        depsToLoad: ['_det', '_pow'],
      },
      {
        name: '_det',
        label: 'Тип двигателя',
        type: 'select',
        depsToLoad: ['_cap', '_pow'],
      },
      {
        name: '_pow',
        label: 'Мощность двигателя, л.с.',
        type: 'select',
      },
    ],
  },
]
