export const getUserPicPath = (fileName: string, w = 0): string => {
  if (!process.env.REACT_APP_BASE_URL) {
    throw new Error('env REACT_APP_BASE_URL is undefined')
  }

  if (w) {
    return `${process.env.REACT_APP_BASE_URL}/uploads/userpic/${fileName}?w=${w}`
  }

  return `${process.env.REACT_APP_BASE_URL}/uploads/userpic/${fileName}`
}

export const getProductPicPath = (urlPath: string, w = 0): string => {
  if (!process.env.REACT_APP_BASE_URL) {
    throw new Error('env REACT_APP_BASE_URL is undefined')
  }

  if (w) {
    return `${process.env.REACT_APP_BASE_URL}${urlPath}?w=${w}`
  }

  return `${process.env.REACT_APP_BASE_URL}${urlPath}`
}
