import { BaseQueryFn, fetchBaseQuery } from '@reduxjs/toolkit/query'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import { Mutex } from 'async-mutex'
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'

import { TAppState } from '../store'
import { baseUrl } from './baseUrl'
import { getWebsiteBaseQuery } from './baseQueryWebsite'
import { notificationsSlice } from '../reducers/notifications'
import { helloSlice } from '../reducers/hello'



const mutex = new Mutex()

const timeout = (ms: number): Promise<number> => {
  return new Promise(resolve => setTimeout(resolve, ms))
}


export const getConsoleBaseQuery = (): BaseQueryFn => {
  const { addNotification } = notificationsSlice.actions
  const websiteBaseQuery = getWebsiteBaseQuery() // for update tokens
  const consoleBaseQuery = fetchBaseQuery({
    baseUrl: baseUrl.website + baseUrl.websiteApi,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as TAppState).hello.token

      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }

      return headers
    },
  })

  // const customFetchBase: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =
  return async ({ onSuccess, ...args }, api, extraOptions) => {
    // const hasToken = (api.getState() as AppState).user.token.length > 0

    // Timeout used for prevet sending request twice by double click on form button.
    // Tip: use disabled argument according to isFetching
    if (args.method !== 'GET') {
      await timeout(250)
    }

    // wait until the mutex is available without locking it
    await mutex.waitForUnlock()
    let res = await consoleBaseQuery(args, api, extraOptions)


    if (onSuccess) {
      if (res?.meta?.response?.ok) {
        await onSuccess(api.dispatch)
      }
    }

    const err = getErrorResponse(res)

    if (err.code !== 0 && err.code !== 401) {
      api.dispatch(addNotification(
        {
          message: `Error: ${err.code}: ${err.message}. ${err.details}`,
          type: 'error',
        },
      ))
    }

    if (err.code === 401) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire()

        try {
          let body = undefined
          if (process.env.NODE_ENV === 'development') {
            const refreshToken = sessionStorage.getItem('refreshToken')
            if (refreshToken) {
              body = { refreshToken }
            }
          }

          const resRefresh = await websiteBaseQuery({
            url: '/user/tokens',
            method: 'PUT',
            body: body,
          }, api, extraOptions)

          const errRefresh = getErrorResponse(resRefresh)

          if (errRefresh.code === 0) {
            const data = resRefresh.data as { token: string, refreshToken?: string }

            if (data.token) {
              api.dispatch(helloSlice.actions.setToken(data.token))
            }

            if ('refreshToken' in data && data.refreshToken) {
              sessionStorage.setItem('refreshToken', data.refreshToken)
            }

            res = await consoleBaseQuery(args, api, extraOptions)
          } else {
            if (process.env.NODE_ENV === 'production') {
              window.location.href = baseUrl.website + '/user'
            }
          }
        } finally {
          // release must be called once the mutex should be released again.
          release()
        }
      } else {
        // wait until the mutex is available without locking it
        await mutex.waitForUnlock()
        res = await consoleBaseQuery(args, api, extraOptions)
      }
    }

    return res
  }
}


const getErrorResponse = (res: QueryReturnValue): { code: number, message: string, details: string } => {
  const result = {
    code: 0,
    message: '',
    details: '',
  }

  if (res.error) {
    const err = res.error as FetchBaseQueryError

    if (typeof err.status === 'number') {
      result.code = err.status
      if (err.data) {
        const data = err.data as { message?: string, details: string }

        result.details = data.details

        result.message = data.message || ''
      }
    }

    if (typeof err.status === 'string') {
      if (err.status === 'PARSING_ERROR') {
        result.code = err.originalStatus
        result.message = err.status
      } else {
        result.code = -1
        result.message = `Внештатная ситуация - ${err.status}. Повторите запрос позже`
      }
    }
  }

  return result
}
