import * as React from 'react'

import Notification from './Notification'
import { useAppSelector } from '../../../hooks/redux'


const Notifications = (): JSX.Element => {
  const notifications = useAppSelector(state => state.notifications)

  return (
    <div
      style={{
        position: 'fixed',
        bottom: 20,
        display: 'flex',
        flexDirection: 'column-reverse',
        width: 400,
      }}
    >
      {notifications.item &&
        <Notification {...notifications.item}/>
      }
    </div>
  )
}

export default Notifications
