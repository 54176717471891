import { TGetProductsFilters } from '../../../../redux/api/apiProducts'


export enum sortDirection {
  Ascending = 'asc',
  Descending = 'desc',
}

export enum sortBy {
  IssuedAt = 'iss',
  TurbineNumber = 'num',
  CrossCount = 'crossCount',
}

export const filters = {
  capacity: 'cap',
  cylinders: 'cyl',
  det: 'det',
  fuel: 'fue',
  issued_at: 'iss',
  manufacturer: '_man',
  car_model: 'mod',
  pow: 'pow',
  car: '_car',
}

export const defaultFiltersState: TGetProductsFilters = {
  l: 50,
  s: sortBy.IssuedAt,
  sd: sortDirection.Ascending,
}

export const initUserFilters = (): TGetProductsFilters => {
  return {
    ...defaultFiltersState,
  }
}
