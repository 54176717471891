import * as React from 'react'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ListItemIcon from '@mui/material/ListItemIcon'
import MenuItem from '@mui/material/MenuItem'
import { Typography } from '@mui/material'

import { TAction } from './constants/actionsMap'


type ActionItemProps = {
    item: TAction
    onClickCallback: (itemKey: string) => void
    selected?: boolean
    shouldHaveExpand?: boolean
  }

const ActionItem = (props: ActionItemProps): JSX.Element => {
  const expandIcon = props.shouldHaveExpand && (props.selected ? <ExpandLess/> : <ExpandMore/>)

  return (
    <MenuItem
      key={props.item.key}
      onClick={(): void => props.onClickCallback(props.item.key)}
      selected={props.selected}
    >
      <ListItemIcon>{props.item.icon}</ListItemIcon>

      <Typography variant="inherit" noWrap>
        {props.item.title}
      </Typography>

      <div style={{ marginLeft: 'auto' }}>{expandIcon}</div>
    </MenuItem>
  )
}

export default ActionItem
