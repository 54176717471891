import { combineReducers } from '@reduxjs/toolkit'

import { apiProducts } from '../api/apiProducts'
import { api } from '../api/api'
import { apiWebsite } from '../api/apiWebsite'

import notifications from './notifications'
import user from './user'
import hello from './hello'
import sidebar from './sidebar'
import product from './products'
import syncBrowser from './syncBrowser'


const rootReducer = combineReducers({
  notifications,
  hello,
  product,
  sidebar,
  user,
  syncBrowser,
  [apiWebsite.reducerPath]: apiWebsite.reducer,
  [apiProducts.reducerPath]: apiProducts.reducer,
  [api.reducerPath]: api.reducer,
})

export default rootReducer
