import * as React from 'react'
import FolderIcon from '@mui/icons-material/Folder'
import PeopleIcon from '@mui/icons-material/People'


export type TAction = {
    title: string
    icon: React.ReactNode
    key: string
    child: Array<TAction>,
    route?: string
}

export const actionsMap: Array<TAction> = [
  {
    title: 'Товары',
    icon: <FolderIcon/>,
    key: 'products',
    route: '',
    child: [
      {
        title: 'Турбокомпрессоры',
        icon: null,
        key: 'turbocompressors',
        route: 'turbocompressors',
        child: [],
      },
    ],
  },
  {
    title: 'Пользователи',
    key: 'users',
    icon: <PeopleIcon />,
    route: '',
    child: [],
  },
]
