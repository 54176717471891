import { BaseQueryFn, fetchBaseQuery } from '@reduxjs/toolkit/query'
import { baseUrl } from './baseUrl'


export const getWebsiteBaseQuery = (): BaseQueryFn  => {
  return fetchBaseQuery({
    baseUrl: baseUrl.website + baseUrl.websiteApi,
    credentials: 'include',
  })
}
