import React from 'react'
import { Helmet } from 'react-helmet'
import ReactDOM from 'react-dom/client'
import { createTheme, CssBaseline, ThemeProvider, ThemeOptions } from '@mui/material'
import { ruRU } from '@mui/material/locale'
import '@fontsource-variable/inter'
import { Provider } from 'react-redux'
import store from './redux/store'
import Routing from './Routing'




const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
)

const themeOptions: ThemeOptions = {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#f4f6f8',
          'WebkitFontSmoothing': 'antialiased',
          'MozOsxFontSmoothing': 'grayscale',
        },
      },
    },
  },
  typography: {
    fontFamily: [
      'Inter Variable',
      'sans-serif',
    ].join(','),
  },
}

const muiTheme = createTheme(themeOptions, ruRU)

root.render(
  <ThemeProvider theme={muiTheme}>
    <CssBaseline />

    <Helmet>
      <title>PIM</title>
      <link rel="icon" type="image/png" href='/favicon.ico' sizes="16x16" />
    </Helmet>

    <Provider store={store}>
      <Routing />
    </Provider>
  </ThemeProvider>,
)
