import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { apiProducts, TGetProductResponse, TProductListItemSyncDest, TSingleProductListItem } from '../api/apiProducts'
import { TAppState } from '../store'
import { TFilter as TQueryFilter, queryFilters } from '../../components/Layout/TurboCompressors/constants/queryFilters'
import { defaultFiltersState, sortBy, sortDirection } from '../../components/Layout/TurboCompressors/constants/filters'


export type TFiltersOptions = {
  [key: string]: string[]
}

export type TTextFilters = {
  [key: string]: string | number | null
}

export type TReoderImages = {
  startIndex: number,
  endIndex: number
}

export type TAdditionalFilters = {
  l?: number
  sd?: sortDirection
  s?: sortBy
  p?: number
}

const initFiltersOptions = (): TFiltersOptions => {
  return queryFilters.reduce((acc: TFiltersOptions, section) => {
    section.filters.forEach((filter: TQueryFilter) => {
      if (filter.type === 'select') {
        acc[filter.name] = []
      }
    })
    return acc
  }, {})
}

const initTextFilters = (): TTextFilters => {
  return queryFilters.reduce((acc: TTextFilters, section) => {
    section.filters.forEach((filter: TQueryFilter) => {
      if (filter.type === 'input') {
        acc[filter.name] = ''
      }
    })
    return acc
  }, {})
}

export type TFilters = {
  options: TFiltersOptions,
  textFilters: TTextFilters,
  additionalFilters: TAdditionalFilters,
}

export type TProducts = {
    loading: boolean,
    results: TSingleProductListItem[],
    isError: boolean,
    total: number,
    paginationCount: number
}

type TProductsState = {
  product: TGetProductResponse | null,
  products: TProducts,
  filters: TFilters,
}

const initialState: TProductsState = {
  product: null,
  products: {
    loading: false,
    results: [],
    isError: false,
    total: 0,
    paginationCount: 0,
  },
  filters: {
    options: initFiltersOptions(),
    textFilters: initTextFilters(),
    additionalFilters: defaultFiltersState,
  },
}

export const productsSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setFilterOptions: (state, action: PayloadAction<TFiltersOptions>) => {
      state.filters.options = {
        ...state.filters.options,
        ...action.payload,
      }
    },
    setTextFilters: (state, action: PayloadAction<TTextFilters>) => {
      state.filters.textFilters = {
        ...state.filters.textFilters,
        ...action.payload,
      }
    },
    setAdditionalFilters: (state, action: PayloadAction<TAdditionalFilters>) => {
      state.filters.additionalFilters = {
        ...state.filters.additionalFilters,
        ...action.payload,
      }
    },
    reorderProductImages: (state, action: PayloadAction<TReoderImages>) => {
      const { startIndex, endIndex } = action.payload

      if (state.product && state.product.img) {
        const result = Array.from(state.product.img)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        state.product.img = result
      }
    },
    incrementProductRev: (state) => {
      if (state.product) {
        state.product.rev += 1
      }
    },
    setProductsSynced: (
      state,
      { payload }: PayloadAction<{ productsId: string[], syncDest: TProductListItemSyncDest }>,
    ) => {
      state.products.results.forEach(prod => {
        if (payload.productsId.indexOf(prod.id) >= 0) {
          prod.syncDest.push(payload.syncDest)
        }
      })
    },
    unsetProductsSynced: (
      state,
      { payload }: PayloadAction<{ productsId: string[], syncDestId: number }>,
    ) => {
      state.products.results.forEach(prod => {
        if (payload.productsId.indexOf(prod.id) >= 0) {
          prod.syncDest = prod.syncDest.filter(d => d.id !== payload.syncDestId)
        }
      })
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(apiProducts.endpoints.getOne.matchFulfilled),
        (state, action) => {
          state.product = action.payload
        },
      )

      .addMatcher(
        isAnyOf(apiProducts.endpoints.get.matchFulfilled),
        (state, action) => {
          const { results, total } = action.payload

          state.products.results = results
          state.products.isError = false
          state.products.loading = false
          state.products.total = total
          state.products.paginationCount = total && state.filters.additionalFilters.l ?
            Math.ceil(total / state.filters.additionalFilters.l) :
            0
        },
      )

      .addMatcher(
        isAnyOf(apiProducts.endpoints.get.matchPending),
        (state) => {
          state.products.loading = true
          state.products.isError = false
        },
      )

      .addMatcher(
        isAnyOf(apiProducts.endpoints.get.matchRejected),
        (state) => {
          state.products.loading = false
          state.products.isError = true
          state.products.results = []
        },
      )

      .addMatcher(
        isAnyOf(apiProducts.endpoints.uploadImages.matchFulfilled),
        (state, action) => {
          const success_images = action.payload.img
            .filter(x => x.ok === true)
            .map(x => ({
              id: x.id || 0,
              url: x.url || '',
              wg: x.wg || 0,
              name: x.name,
              createdAt: new Date().toISOString(),
            }))

          if (state.product && state.product.img) {
            state.product.img = [...success_images, ...state.product.img]
          }
        },
      )
      // TODO rename it to updateImageWeight
      .addMatcher(
        isAnyOf(apiProducts.endpoints.uploadImageWeight.matchFulfilled),
        (state) => {
          if (state.product) {
            state.product.rev += 1
          }
        },
      )

      .addMatcher(
        isAnyOf(apiProducts.endpoints.deleteImage.matchFulfilled),
        (state, action) => {
          if (state.product) {
            const { image_id } = action.meta.arg.originalArgs
            state.product.rev += 1
            state.product.img = state.product.img.filter(x => x.id !== image_id)
          }
        },
      )
  },
})

export const selectProductInfo = (state: TAppState): TGetProductResponse | null => state.product.product
export const selectProducts = (state: TAppState): TProducts => state.product.products
export const selectFilters = (state: TAppState): TFilters => state.product.filters

export const {
  setFilterOptions,
  setTextFilters,
  setAdditionalFilters,
  reorderProductImages,
  incrementProductRev,
} = productsSlice.actions

export default productsSlice.reducer
