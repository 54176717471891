import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { TRHello, TRUserItem } from '../../models'
import { api } from '../api/api'
import { apiWebsite } from '../api/apiWebsite'


type TMergeUser = {
  id: number;
  role?: string;
  fullname?: string;
  nickname?: string;
  isBlocked?: boolean;
  syncDest?: number;
}


const initialState: TRHello & { token: string }= {
  me: {
    id: 0,
    role: '',
    fullname: '',
    nickname: '',
    isBlocked: false,
    online: null,
    userpic: '',
    syncDest: 0,
  },
  users: [],
  syncDest: [],
  token: '',
}

export const helloSlice = createSlice({
  name: 'hello',
  initialState,
  reducers: {
    addUser: (state, action: PayloadAction<TRUserItem>) => {
      state.users = [...state.users, action.payload]
    },
    mergeUser: (state, { payload }: PayloadAction<TMergeUser>) => {
      if (payload.id === state.me.id) {
        state.me = {
          ...state.me,
          ...payload,
        }
      } else {
        state.users = state.users.map(u => u.id === payload.id ? { ...u, ...payload } : u)
      }
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(api.endpoints.sayHello.matchFulfilled, (state, action) => {
        Object.assign(state, action.payload)
      })
      .addMatcher(api.endpoints.getUsers.matchFulfilled, (state, action) => {
        state.users = action.payload
      })
      .addMatcher(api.endpoints.register.matchFulfilled, (state, action) => {
        state.users.push(action.payload)
      })
      .addMatcher(
        isAnyOf(
          apiWebsite.endpoints.login.matchFulfilled,
          apiWebsite.endpoints.refreshToken.matchFulfilled,
        ),
        (state, action) => {
          state.token = action.payload.token

          if (process.env.NODE_ENV === 'development' && action.payload.refreshToken) {
            sessionStorage.setItem('refreshToken', action.payload.refreshToken)
          }
        },
      )
      .addMatcher(apiWebsite.endpoints.logout.matchFulfilled, (state) => {
        state.token = ''

        if (process.env.NODE_ENV === 'development') {
          sessionStorage.removeItem('refreshToken')
        }
      })
      // .addMatcher(api.endpoints?.updateSettings.matchFulfilled, (state, action) => {
      //   action.meta.arg.originalArgs.syncDest
      // })
  },
})

export default helloSlice.reducer
