import { createSlice } from '@reduxjs/toolkit'
import { TAppState } from '../store'


type TState = {
    open: boolean
}

const initialState: TState = {
  open: false,
}

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleSidebar(state) {
      state.open = !state.open
    },
  },
})

export const selectSidebarOpen = (state: TAppState): boolean => state.sidebar.open

export default sidebarSlice.reducer
