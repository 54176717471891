import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api } from '../api/api'
import { TRUser, TRPutUserProfile, TRDeleteUserToken, TRUserSyncDestination } from '../../models'


const initialState: TRUser = {
  id: 0,
  nickname: '',
  firstname: '',
  lastname: '',
  role: '',
  bio: '',
  userpic: '',
  isBlocked: false,
  devices: [],
  syncDest: [],
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateProfile: (state, action: PayloadAction<TRPutUserProfile>) => {
      state.firstname = action.payload.firstname
      state.lastname = action.payload.lastname
      state.bio = action.payload.bio
    },
    updateNickname: (state, action: PayloadAction<string>) => {
      state.nickname = action.payload
    },
    deleteToken: (state, action: PayloadAction<TRDeleteUserToken>) => {
      state.devices = state.devices.filter(dev => dev.id !== action.payload.deviceId)
    },
    setSyncDest: (state, action: PayloadAction<TRUserSyncDestination[]>) => {
      state.syncDest = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(api.endpoints.getUser.matchFulfilled, (state, { payload }) => {
        Object.assign(state, payload)
      })
  },
})


export default userSlice.reducer
