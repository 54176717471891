import { createApi } from '@reduxjs/toolkit/query/react'
import { Dispatch } from '@reduxjs/toolkit'
import { getConsoleBaseQuery } from './baseQueryConsole'

import { sortBy, sortDirection } from '../../components/Layout/TurboCompressors/constants/filters'
import { notificationsSlice } from '../reducers/notifications'


export type TVehicle = {
  cap: number
  cyl: number
  det: string
  fue: string
  iss: number
  man: string
  mod: string
  pow: number
}

export type TProduct = {
  id: string
  man?: string
  num?: string
  veh?: TVehicle[]
}

export type TProductListItemSyncDest = {
  id: number
  pageId: number
}

export type TSingleProductListItem = {
  id: string
  man?: string
  num?: string
  img?: string
  crossCount: number
  syncDest: TProductListItemSyncDest[]
}


export type TFilter = {
  '_car': string[]
  '_man': string[]
}

export type TGetProductsFilters = {
  _car?: string
  _man?: string
  _mod?: string
  q?: string
  p?: number
  l?: number
  s?: sortBy
  sd?: sortDirection,
}

export type TProductCross = {
  id: string
  num: string
}

export type TGetProductResponse = TProduct & {
  cat: string,
  oe: string[],
  img: TProductImage[],
  cross: TProductCross[]
  rev: number
}

export type TGetProductsFiltersKeys = keyof TGetProductsFilters

type TGetProductsResponse = {
  results: TSingleProductListItem[]
  filter: Record<string, string[]>
  total: number
}

type TUploadImagesRequest = {
  id: string | undefined
  formData: FormData
}

export type TProductImage = {
  id: number
  wg: number
  createdAt: string
  url: string
  name: string
}

// TODO add extends
export type TUploadImagesResponseItem = {
  id?: number
  wg?: number
  name: string
  url?: string
  ok: boolean
  errors?: string[]
}

export type TUploadImagesResponse = {
  img: TUploadImagesResponseItem[]
}

export type TUploadImageWeightRequest = {
  id: string
  image_id: number
  rev: number
  wg: number
}

export type TDeleteImageRequest = {
  id: string
  image_id: number
  rev: number
}


export const apiProducts = createApi({
  reducerPath: 'products',
  baseQuery: getConsoleBaseQuery(),
  endpoints: (builder) => ({
    get: builder.query<TGetProductsResponse, string>({
      query: (queryParams: string) => ({
        url: `/products?c=turbine&${queryParams}`,
      }),
    }),
    getOne: builder.query<TGetProductResponse, string | undefined>({
      query: (id) => ({
        url: `/products/${id}`,
      }),
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      },
    }),
    uploadImages: builder.mutation<TUploadImagesResponse, TUploadImagesRequest>({
      query: (payload) => ({
        url: `/products/${payload.id}/images`,
        method: 'POST',
        body: payload.formData,
      }),
    }),
    uploadImageWeight: builder.mutation<void, TUploadImageWeightRequest>({
      query: (payload) => ({
        url: `/products/${payload.id}/images/${payload.image_id}`,
        method: 'PUT',
        body: {
          rev: payload.rev,
          wg: payload.wg,
        },
      }),
    }),
    deleteImage: builder.mutation<void, TDeleteImageRequest>({
      query: (payload) => ({
        url: `/products/${payload.id}/images/${payload.image_id}?rev=${payload.rev}`,
        method: 'DELETE',
        onSuccess: async (dispatch: Dispatch): Promise<void> => {
          dispatch(notificationsSlice.actions.addNotification({
            type: 'success',
            message: 'Изображение успешно удалено',
          }))
        },
      }),
    }),
  }),
})

export const {
  useLazyGetQuery,
  useGetOneQuery,
  useUploadImagesMutation,
  useUploadImageWeightMutation,
  useDeleteImageMutation,
} = apiProducts
